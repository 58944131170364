import { NgModule } from '@angular/core';
import { DateRangeComponent } from './date-range/date-range.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IrisBaseModule } from '@iris/iris-base';
import { PipesModule } from './pipes.module';
import { DirectivesModule } from './directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { IrisAuthenticationModule } from '@iris/iris-authentication';
import { IrisOtpModule } from '@iris/iris-otp';
import { TransferPanelComponent } from './transfer-panel/transfer-panel.component';
import { TransferPanelDocumentComponent } from './transfer-panel-document/transfer-panel-document.component';
import { SubmenuComponent } from './submenu/submenu.component';
import { BoxCardComponent } from './box-card/box-card.component';

@NgModule({
  declarations: [
    TransferPanelComponent,
    TransferPanelDocumentComponent,
    BoxCardComponent,
    SubmenuComponent,
    DateRangeComponent,
    DatePickerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    ScrollingModule,
    IrisBaseModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    NgxMaskModule.forRoot(),
    IrisAuthenticationModule,
    IrisOtpModule,
  ],
  exports: [
    TransferPanelComponent,
    TransferPanelDocumentComponent,
    SubmenuComponent,
    BoxCardComponent,
    DateRangeComponent,
    DatePickerComponent,
    ScrollingModule,
    IrisBaseModule,
    PipesModule,
    DirectivesModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IrisAuthenticationModule,
    IrisOtpModule,
  ],
})
export class BaseModule {}
