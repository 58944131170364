import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Team, TeamCreate } from 'src/lib/models';
import { TeamFilter } from './team.types';
import { BaseService } from '../base/base.service';
import { map, shareReplay, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { OtpViewModel, IrisOtpConstants } from '@iris/iris-otp';

@Injectable()
export class TeamService extends BaseService {
  private teamsCaches$: any = {};

  changeTeam(userId: string, teamId: string): Observable<any> {
    return this.http.put(
      `${this.settings.get.eprepaidApi}/user/${userId}`,
      {},
      {
        params: { team: teamId },
      },
    );
  }

  enable(team: Team) {
    return null
  }

  disable(team: Team) {  
  }

  resetCache(): void {
    this.teamsCaches$ = {};
  }  
}
