import { Injectable } from '@angular/core';
import { SessionService } from '@iris/iris-authentication';
import { NavItem, Submenu } from 'src/lib/models/navigation.model';
import { UserHasPermissionService } from '../common/user-has-permission.service';
import { RolePermissions } from '../roles/role.types';

@Injectable()
export class MenuService {
  menusMobile: NavItem[] = [
    {
      href: '/dashboard',
      title: 'Dashboard',
      icon: 'assets/icons/menu/ic-resume.svg',
      hasAccess: false,
      permissions: [RolePermissions.COMPLETE_SUMMARY],
    },
    {
      title: 'Transactions',
      icon: 'assets/icons/menu/ic-transferencias.svg',
      href: '/transfer',
      hasAccess: false,
      permissions: [
        RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
        RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
        RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
        RolePermissions.TRANSACTIONS_BATCHES,
        RolePermissions.TRANSACTIONS_INSCRIBED_ACCOUNTS,
        RolePermissions.IDENTIFICATION_ACCOUNTS,
        RolePermissions.PAYMENTS_PAYROLL_MANUAL,
        RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
        RolePermissions.PAYMENTS_PAYROLL_BATCHES,
        RolePermissions.PAYMENTS_PROVIDERS_BATCHES,
        RolePermissions.OPERATION_DETAILS,
        RolePermissions.CARDS_MANAGEMENT,
      ],
      submenu: [
        {
          isOpen: false,
          title: 'AccountManagement',
          hasAccess: false,
          items: [
            {
              title: 'AccountManagement.InscribeAccount',
              href: '/transfer/inscribed-accounts/inscribe-account',
              hasAccess: false,
              permissions: [RolePermissions.TRANSACTIONS_INSCRIBED_ACCOUNTS],
              image: 'img-cuentas-inscritas',
            },
            {
              title: 'AccountManagement.IdentificationAccount',
              href: '/administration/tags/accounts',
              hasAccess: false,
              permissions: [RolePermissions.IDENTIFICATION_ACCOUNTS],
              image: 'img-cuentas-inscritas',
            },
          ],
        },
        {
          title: 'Transfers',
          hasAccess: false,
          items: [
            {
              title: 'Transfers.InscribedAccounts',
              href: '/transfer/inscribed-accounts',
              permissions: [RolePermissions.TRANSACTIONS_INSCRIBED_ACCOUNTS],
              hasAccess: false,
              image: 'img-cuentas-inscritas',
            },
            {
              title: 'Transfers.OwnIrisAccounts',
              href: '/transfer/own-iris',
              permissions: [RolePermissions.TRANSACTIONS_OWN_ACCOUNTS],
              hasAccess: false,
              image: 'img-cuentas-propias',
            },
            {
              title: 'Transfers.OtherIrisAccounts',
              href: '/transfer/other-iris',
              permissions: [RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK],
              hasAccess: false,
              image: 'img-banco-iris',
            },
            {
              title: 'Transfers.OtherBankAccounts',
              href: '/transfer/other-bank',
              permissions: [RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK],
              hasAccess: false,
              image: 'img-otros-bancos',
            },
            {
              title: 'Transfers.Batch',
              href: '/transfer/batch-transfer',
              permissions: [RolePermissions.TRANSACTIONS_BATCHES],
              hasAccess: false,
              image: 'img-en-lote',
            },
          ],
        },
        {
          title: 'Payments',
          hasAccess: false,
          permissions: [
            RolePermissions.PAYMENTS_PAYROLL_MANUAL,
            RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
            RolePermissions.PAYMENTS_PAYROLL_BATCHES,
            RolePermissions.PAYMENTS_PROVIDERS_BATCHES,
          ],
          items: [
            {
              title: 'Payments.Manual',
              href: '/payment/manual',
              permissions: [
                RolePermissions.PAYMENTS_PAYROLL_MANUAL,
                RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
              ],
              hasAccess: false,
              image: 'img-pagos-manuales',
            },
            {
              title: 'Payments.Draft',
              href: '/payment/draft',
              permissions: [
                RolePermissions.PAYMENTS_PAYROLL_MANUAL,
                RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
              ],
              hasAccess: false,
              image: 'img-pagos-guardados',
            },
          ],
        },
        {
          title: 'TransactionStatus',
          hasAccess: false,
          items: [
            {
              title: 'TransactionStatus.OperationDetail',
              href: '/operations-history',
              hasAccess: false,
              permissions: [RolePermissions.OPERATION_DETAILS],
              image: 'img-cuentas-inscritas',
            },
            {
              title: 'TransactionStatus.Authorizations',
              href: '/pending-transactions/pending/requested',
              permissions: [
                RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
                RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
                RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
                RolePermissions.TRANSACTIONS_BATCHES,
                RolePermissions.CARDS_MANAGEMENT,
              ],
              hasAccess: false,
              image: 'img-autorizacion',
            },
            {
              title: 'TransactionStatus.ScheduledTransactions',
              href: '/pending-transactions/scheduled/transactions',
              permissions: [
                RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
                RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
                RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
                RolePermissions.TRANSACTIONS_BATCHES,
              ],
              hasAccess: false,
              image: 'img-programadas',
            },
            {
              title: 'TransactionStatus.HistoryTransactions',
              hasAccess: false,
              href: '/transactions-history/transactions',
              permissions: [
                RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
                RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
                RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
                RolePermissions.TRANSACTIONS_BATCHES,
              ],
              image: 'img-cuentas-inscritas',
            },
          ],
        },
      ],
    },
    {
      title: 'IrisPay',
      icon: 'assets/img/menu/ic-recaudos.svg',
      href: '/irispay',
      hasAccess: true,
      highlight: false,
    },
    {
      title: 'Company',
      icon: 'img/menu/ic-administracion.svg',
      hasAccess: true,
      permissions: [
        RolePermissions.AREAS_AND_TEAMS,
        RolePermissions.ROLE_AND_LIMITS,
        RolePermissions.USERS,
        RolePermissions.USER_LOGS,
        RolePermissions.PLANS,
        RolePermissions.IDENTIFICATION_TAGS,
        RolePermissions.DOCUMENTS_DOWNLOAD,
      ],
      href: '/administration',
      submenu: [
        {
          isOpen: false,
          title: 'AccountIris',
          items: [
            {
              title: 'AccountIris.Plan',
              href: '/administration/plans/account-selector',
              hasAccess: false,
              permissions: [RolePermissions.PLANS],
              image: 'img-cuentas-inscritas',
            },
            {
              title: 'AccountIris.Documents',
              href: '/administration/document-info/documents-main',
              hasAccess: false,
              permissions: [RolePermissions.DOCUMENTS_DOWNLOAD],
              image: 'img-cuentas-inscritas',
            }
          ],
        },
        {
          title: 'RolesUser',
          items: [
            {
              title: 'RolesUser.RolesLimits',
              href: '/user-management/roles',
              hasAccess: false,
              permissions: [RolePermissions.ROLE_AND_LIMITS],
              image: 'img-cuentas-inscritas',
            },
            {
              title: 'RolesUser.UserProfiles',
              href: '/user-management/users',
              hasAccess: false,
              permissions: [RolePermissions.USERS],
              image: 'img-cuentas-inscritas',
            },
            {
              title: 'RolesUser.UsersLog',
              href: '/user-management/logs',
              hasAccess: false,
              permissions: [RolePermissions.USER_LOGS],
              image: 'img-cuentas-inscritas',
            },
          ],
        },
        {
          title: 'Tags',
          items: [
            {
              title: 'Tags.IdentificationTags',
              href: '/administration/tags/transactions/deposit-accounts',
              hasAccess: false,
              permissions: [RolePermissions.IDENTIFICATION_TAGS],
              image: 'img-cuentas-inscritas',
            },
          ],
        },
        {
          title: 'Iris',
          items: [
            {
              title: 'Iris.Contact',
              href: 'https://www.iris.com.co/home-centro-de-ayuda/',
              hasAccess: true,
              image: 'img-cuentas-inscritas',
              isExternal: true,
            },
            {
              title: 'Iris.Regulation',
              href: 'https://www.iris.com.co/centro-legal/reglament-cuenta-de-ahorros-digital/',
              hasAccess: true,
              image: 'img-cuentas-inscritas',
              isExternal: true,

            },
          ],
        },
      ],
    },
    {
      title: 'Profile',
      href: '/user-management',
      icon: 'img/menu/ic-gesion-de-usuario.svg',
      hasAccess: false,
      permissions: [RolePermissions.NOTIFICATIONS, RolePermissions.USERS],
      submenu: [
        {
          isOpen: false,
          title: 'Configuration',
          items: [
            {
              title: 'Configuration.Notifications',
              href: '/administration/notifications',
              hasAccess: false,
              permissions: [RolePermissions.NOTIFICATIONS],
              image: 'img-cuentas-inscritas',
            },
          ],
        },
        {
          title: 'User',
          items: [
            {
              title: 'User.UserDetail',
              href: '/user-management/users',
              backTo: '/user-management',
              permissions: [RolePermissions.USERS],
              image: 'img-cuentas-inscritas',
              hasAccess: false,
            },
          ],
        },
        {
          title: 'Security',
          items: [
            {
              title: 'Security.ChangePassword',
              href: '/administration/change-password',
              hasAccess: true,
              image: 'img-cuentas-inscritas',
            },
          ],
        },
      ],
    },
  ];

  menus: NavItem[] = [
    {
      href: '/dashboard',
      title: 'Dashboard',
      icon: 'assets/icons/menu/ic-resume.svg',
      hasAccess: false,
      permissions: [RolePermissions.COMPLETE_SUMMARY],
    },
    {
      title: 'Transfers',
      icon: 'assets/icons/menu/ic-transferencias.svg',
      href: '/transfer',
      hasAccess: false,
      permissions: [
        RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
        RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
        RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
        RolePermissions.TRANSACTIONS_BATCHES,
      ],
      submenu: [
        {
          title: 'Transfers.InscribedAccounts.Title',
          href: '/transfer/inscribed-accounts',
          permissions: [RolePermissions.TRANSACTIONS_INSCRIBED_ACCOUNTS],
          hasAccess: false,
          image: 'img-cuentas-inscritas',
          items: []
        },
        {
          title: 'Transfers.Transfer.OwnIrisAccounts',
          href: '/transfer/own-iris',
          permissions: [RolePermissions.TRANSACTIONS_OWN_ACCOUNTS],
          hasAccess: false,
          image: 'img-cuentas-propias',
          items: []
        },
        {
          title: 'Transfers.Transfer.OtherIrisAccounts',
          href: '/transfer/other-iris',
          permissions: [RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK],
          hasAccess: false,
          image: 'img-banco-iris',
          items: []
        },
        {
          title: 'Transfers.Transfer.OtherBankAccounts',
          href: '/transfer/other-bank',
          permissions: [RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK],
          hasAccess: false,
          image: 'img-otros-bancos',
          items: []
        },
        {
          title: 'Transfers.Batch.Title',
          href: '/transfer/batch-transfer',
          permissions: [RolePermissions.TRANSACTIONS_BATCHES],
          hasAccess: false,
          hideOnMobile: true,
          image: 'img-en-lote',
          items: []
        },
      ],
    },
    {
      title: 'Payments',
      icon: 'assets/icons/menu/ic-pagos.svg',
      href: '/payment',
      hasAccess: false,
      permissions: [
        RolePermissions.PAYMENTS_PAYROLL_MANUAL,
        RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
        RolePermissions.PAYMENTS_PAYROLL_BATCHES,
        RolePermissions.PAYMENTS_PROVIDERS_BATCHES,
      ],
      submenu: [
        {
          title: 'Payments.Tabs.CSV',
          // href: '/payment/csv',
          href: '',
          permissions: [
            RolePermissions.PAYMENTS_PAYROLL_BATCHES,
            RolePermissions.PAYMENTS_PROVIDERS_BATCHES,
          ],
          hasAccess: false,
          image: 'img-pagos-en-lote',
          items: []
        },
        {
          title: 'Payments.Tabs.MANUAL',
          href: '/payment/manual',
          permissions: [
            RolePermissions.PAYMENTS_PAYROLL_MANUAL,
            RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
          ],
          hasAccess: false,
          image: 'img-pagos-manuales',
          items: []
        },
        {
          title: 'Payments.Tabs.DRAFT',
          href: '/payment/draft',
          permissions: [
            RolePermissions.PAYMENTS_PAYROLL_MANUAL,
            RolePermissions.PAYMENTS_PROVIDERS_MANUAL,
          ],
          hasAccess: false,
          image: 'img-pagos-guardados',
          items: []
        },
      ],
    },
    {
      title: 'PendingTransactions',
      icon: 'assets/icons/menu/ic-pending-transactions.svg',
      permissions: [
        RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
        RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
        RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
        RolePermissions.TRANSACTIONS_BATCHES,
        RolePermissions.CARDS_MANAGEMENT,
      ],
      href: '/pending-transactions',
      hasAccess: false,
      submenu: [
        {
          title: 'PendingTransactions.Authorization',
          href: 'pending/requested',
          permissions: [
            RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
            RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
            RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
            RolePermissions.TRANSACTIONS_BATCHES,
            RolePermissions.CARDS_MANAGEMENT,
          ],
          hasAccess: false,
          image: 'img-autorizacion',
          items: []
        },
        {
          title: 'Transactions.ScheduledTransactions.Title',
          href: 'scheduled/transactions',
          permissions: [
            RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
            RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
            RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
            RolePermissions.TRANSACTIONS_BATCHES,
          ],
          hasAccess: false,
          image: 'img-programadas',
          items: []
        },
      ],
    },
    {
      title: 'TransactionsHistory',
      icon: 'assets/icons/menu/ic-gestion-de-transacciones.svg',
      hasAccess: false,
      href: '/transactions-history/transactions',
      permissions: [
        RolePermissions.TRANSACTIONS_OWN_ACCOUNTS,
        RolePermissions.TRANSACTIONS_ACCOUNTS_SAME_BANK,
        RolePermissions.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
        RolePermissions.TRANSACTIONS_BATCHES,
      ],
    },
    {
      title: 'AccountManagement',
      icon: 'assets/icons/menu/ic-gesion-de-usuario.svg',
      href: '/user-management',
      permissions: [
        RolePermissions.USERS,
        RolePermissions.ROLE_AND_LIMITS,
        RolePermissions.USER_LOGS,
        RolePermissions.AREAS_AND_TEAMS,
      ],
      hasAccess: false,
      submenu: [
        {
          title: 'AccountManagement.RoleManagement.Title',
          href: 'roles',
          hasAccess: false,
          permissions: [RolePermissions.ROLE_AND_LIMITS],
          image: 'img-roles-y-limites',
          items: []
        },
        {
          title: 'AccountManagement.UserManagement.Title',
          href: 'users',
          permissions: [RolePermissions.USERS],
          hasAccess: false,
          image: 'img-cuentas-de-usuarios',
          items: []
        },
        {
          title: 'Administration.Logs.Title',
          href: 'logs',
          hasAccess: false,
          permissions: [RolePermissions.USER_LOGS],
          image: 'img-log-de-usuarios',
          items: []
        },
      ],
    } /*
      {
        title: 'Documents',
        icon: 'assets/icons/menu/ic-documents.svg',
        href: '/documents',
        hasAccess: true,
      },*/,
    {
      title: 'Administration',
      icon: 'assets/icons/menu/ic-administracion.svg',
      href: '/administration',
      hasAccess: true,
      submenu: [
        {
          title: 'Administration.ChangePassword.Title',
          href: 'change-password',
          hasAccess: true,
          image: 'img-cambiar-contrasena',
          items: []
        },
        {
          title: 'Administration.Tags.Tabs.Transactions',
          href: 'tags/transactions',
          hasAccess: false,
          permissions: [RolePermissions.IDENTIFICATION_TAGS],
          image: 'img-tag-de-identificacion',
          items: []
        },
        {
          title: 'Administration.Tags.Tabs.Accounts',
          href: 'tags/accounts',
          hasAccess: false,
          permissions: [RolePermissions.IDENTIFICATION_ACCOUNTS],
          image: 'img-identificacion-de-cuentas',
          items: []
        },
        {
          title: 'Administration.Plans.Title',
          href: 'plans/account-selector',
          hasAccess: false,
          permissions: [RolePermissions.PLANS],
          image: 'img-plans',
          disabled: true,
          items: []
        },
        {
          title: 'Administration.Notifications.Title',
          href: 'notifications',
          hasAccess: false,
          permissions: [RolePermissions.NOTIFICATIONS],
          image: 'img-notificaciones',
          items: []
        },
      ],
    },
  ];

  constructor(
    private hasPermissionService: UserHasPermissionService,
    private sessionService: SessionService
  ) {
    this.handleUserPermissions();
  }

  handleUserPermissions() {
    this.menusMobile.map(menu => {
      if (menu.permissions?.length > 0) {
        menu.hasAccess = this.hasPermissionService.hasPermission(
          menu.permissions,
        );
      }
      menu.submenu?.forEach(async submenu => {
        submenu.items.forEach(item =>{
          if (item.permissions?.length > 0) {
            item.hasAccess = this.hasPermissionService.hasPermission(item.permissions);
           }
        });
      });
    });
  }

  handleUserPermissionSubmenus() {
    this.menus.map(menu => {
      if (menu.permissions?.length > 0) {
        menu.hasAccess = this.hasPermissionService.hasPermission(
          menu.permissions,
        );
      }
      menu.submenu?.forEach(async submenu => {
        if (submenu.permissions?.length > 0) {
          submenu.hasAccess = this.hasPermissionService.hasPermission(
            submenu.permissions,
          );
        }
      });
    });
  }

  async getMenus(): Promise<NavItem[]> {
    await this.sessionService.refreshToken();
    this.handleUserPermissions();
    return this.menus;
  }

  async getSubMenuMobile(menuTitle: string): Promise<Submenu[]> {
    this.handleUserPermissions();
    return this.menusMobile.find(menu => menu.title === menuTitle).submenu;
  }

  
  async getSubMenu(menuTitle: string): Promise<Submenu[]> {
    this.handleUserPermissionSubmenus();
    return this.menus.find(menu => menu.title === menuTitle).submenu;
  }
}
