import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Area, AreaFilter, User, AreaCreate } from 'src/lib/models';
import { BaseService } from '../base/base.service';
import { UserFilter } from '../user/user.types';
import { map, shareReplay, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { IrisOtpConstants, OtpViewModel } from '@iris/iris-otp';

@Injectable()
export class AreasService extends BaseService {
  private areasCaches$: any = {};
  private userCaches$: any = {};
  private areaModel: Area;

  getById(areaId: string): Observable<Area> {
    return null
   }

  getUserArea(filters: UserFilter = {}, cached?: boolean): Observable<User[]> {
    if (cached || cached === undefined) {
      const key = btoa(JSON.stringify(filters));
      const cache$ = this.userCaches$[key];

      if (!cache$) {
        this.userCaches$[key] = this.getUserArea(filters, false).pipe(
          shareReplay(),
        );
      }

      return this.userCaches$[key];
    }

    return this.http.get<User[]>(
      `${this.settings.get.eprepaidApi}/user/detail`,
      {
        params: filters as HttpParams,
      },
    );
  }

   create(area: AreaCreate, otp: OtpViewModel): Observable<any> {    
    return null
  }

  resetCache(): void {
    this.areasCaches$ = {};
  }

  enable(area: Area) {
    return null
  }

  disable(area: Area) {
  }  
}
