import { Observable } from 'rxjs';
import {
  DepositAccountsSummary,
  DepositAccount,
  DepositAccountProductType,
  DocumentDetailViewModel,
  DocumentInfoModel
} from '../../models/depositaccount.model';
import { map, shareReplay } from 'rxjs/operators';
import { BaseService } from '../base/base.service';
import { AccountDetailViewModel } from 'src/app/transfer/inscribe-account/account-detail.type';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DepositAccountService extends BaseService {
  private depositAccountsCache$: {};

  get(
    productTypes?: DepositAccountProductType[],
    ignoreCache: boolean = false,
  ): Observable<DepositAccount[]> {
    productTypes = productTypes || [DepositAccountProductType.SAVINGS_ACCOUNT];
    const cacheStr = JSON.stringify(productTypes);

    if (!this.depositAccountsCache$) this.depositAccountsCache$ = {};

    if (!this.depositAccountsCache$[cacheStr] || ignoreCache) {
      this.depositAccountsCache$[cacheStr] = this.fetchDepositAccounts(
        productTypes,
      ).pipe(shareReplay());
    }

    return this.depositAccountsCache$[cacheStr];
  }

  getAll(ignoreCache: boolean = false) {
    return this.get(
      [
        DepositAccountProductType.SAVINGS_ACCOUNT,
        DepositAccountProductType.CONTROL_ACCOUNT,
      ],
      ignoreCache,
    );
  }

  getSavingsAccounts() {
    return this.get([DepositAccountProductType.SAVINGS_ACCOUNT]);
  }

  getControlAccounts() {
    return this.get([DepositAccountProductType.CONTROL_ACCOUNT]);
  }

  getSummary(): Observable<DepositAccountsSummary> {
    return this.http.get<DepositAccountsSummary>(
      `${this.settings.get.depositAccountsApi}/summary`,
    );
  }

  changePlan(depositAccountId: string, planId: string): Observable<void> {
    return this.http.put<void>(
      `${this.settings.get.depositAccountsApi}/${depositAccountId}/plan/${planId}`,
      null,
    );
  }

  resetCache() {
    this.depositAccountsCache$ = {};
  }

  private fetchDepositAccounts(
    productTypes: DepositAccountProductType[],
  ): Observable<DepositAccount[]> {
    return this.http
      .get<DepositAccount[]>(`${this.settings.get.depositAccountsApi}`, {
        params: {
          productType: productTypes,
        } as any,
      })
      .pipe(
        map(depositAccounts =>
          depositAccounts.sort((a, b) => {
            return a.name.localeCompare(b.name);
          }),
        ),
      );
  }

  publicInfo(accountId: string): Observable<AccountDetailViewModel> {
    return this.http.get<AccountDetailViewModel>(
      `${this.settings.get.depositAccountsApi}/${accountId}/publicinfo`,
    );
  }

  downloadFile(DocumentInfoModel: DocumentInfoModel): Observable<DocumentDetailViewModel> {
    return this.http.post<DocumentDetailViewModel>(
      `${this.settings.get.depositAccountsApi}/documentDownload`, 
      DocumentInfoModel,
    );
  }

}
